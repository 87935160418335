import React from "react";

export default function PrivacyPolicy() {
  return (
    <div className="pt-16 pb-48 max-w-4xl mx-auto px-4">
      <h1 className="font-ibm text-[40px] font-bold mb-4">Privacy Policy</h1>
      <div className="font-ibm font-light">
        <p>At Lawton Connect, we are committed to protecting the privacy of our customers and the users of this site. This privacy
        policy statement provides information about how we gather and use of information. Your use of this website indicates you
        agree with the terms and conditions outlined in this privacy policy.
        </p>
        <br/>

        <p className="text-[20px] font-din font-bold"> Personal Information We Collect </p>

        <br/>

        <p>When you visit the Site, we automatically collect certain information about your device, including information about your
          web browser, IP address, time zone, and some of the cookies that are installed on your device. </p>

        <br/>

        <p>Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what
        websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this
          automatically-collected information as “Device Information.”</p>

        <br/>

        <p>We collect Device Information using the following technologies:</p>
        <ul className="list-disc pl-5">
            <li>“Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier.
              For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.</li>
            <li>“Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service
              provider, referring/exit pages, and date/time stamps.</li>
            <li>“Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site.</li>
        </ul>
        <br/>

        <p>Additionally when you make a purchase or attempt to make a purchase through the Site, we collect certain information
          from you, including your name, billing address, shipping address, payment information (including credit card numbers),
          email address, and phone number. We refer to this information as “Order Information.”</p>
        <br/>

        <p>When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Order
          Information.</p>

        <br/>

        <p className="text-[20px] font-din font-bold ">How do we use your personal information?</p>

        <br/>

        <p>We use the Order Information that we collect generally to fulfill any orders placed through the Site (including processing
          your payment information, arranging for shipping, and providing you with invoices and/or order confirmations).</p>

        <br/>

        <p>Additionally, we use this Order Information to:</p>
        <ul className="list-disc pl-5">
            <li>Communicate with you;</li>
            <li>Screen our orders for potential risk or fraud; and</li>
            <li>When in line with the preferences you have shared with us, provide you with information or advertising relating to our
                products or services.</li>
        </ul>

        <br/>

        <p>We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address),
        and more generally to improve and optimize our Site (for example, by generating analytics about how our customers
          browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).</p>

        <br/>

        <p className="text-[20px] font-din font-bold">Sharing Your Personal Information</p>
        <br/>

        <p>We share your Personal Information with third parties to help us use your Personal Information, as described above. For example,
          we use Google Analytics to help us understand how our customers use the Site--you can read more about how
          Google uses your Personal Information here: https://www.google.com/intl/en/policies/privacy/</p>

          <br/>

        <p>You can also opt-out of Google Analytics here: https://tools.google.com/dlpage/gaoptout</p>

          <br/>

        <p>Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a
          subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.
        </p>

        <br/>

        <p className="text-[20px] font-din font-bold">Behavioral Advertising</p>
        <br/>

        <p>As described above, we use your Personal Information to provide you with targeted advertisements or marketing
          communications we believe may be of interest to you. For more information about how targeted advertising works, you can
          visit the Network Advertising Initiative’s (“NAI”) educational page at: http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work </p>

        <br/>

        <p>You can opt out of targeted advertising by visiting the websites by unsubscribing at any time.</p>

        <br/>

        <p className="font-din font-bold text-[20px]">Do NOT Track</p>

        <br/>

        <p>Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your
          browser.</p>

        <br/>

        <p className="font-din font-bold text-[20px]"> Your Rights </p>

        <br/>

        <p>If you are a European resident, you have the right to access personal information we hold about you and to ask that your
        personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through
        the contact information below.</p>

        <br/>

        <p>Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we
          might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business
          interests listed above. Additionally, please note that your information will be transferred outside of Europe, including to
          Canada and the United States.</p>

        <br/>

        <p className="font-din font-bold text-[20px]"> Changes </p>

        <br/>

        <p> We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other
          operational, legal or regulatory reasons.</p>

        <br/>

        <p className="font-din font-bold text-[20px]"> Contact Us </p>

        <br/>

        <p>For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please
        contact us by e-mail at hello@lawtonconnect.com or by mail using the address provided below:</p>

        <br/>

        <p>Lawton Connect</p>
        <p>649 Triumph Court</p>
        <p>Orlando, FL, 32805, United States</p>
        <br/>
        <br/>

        Effective date: April 17, 2024
      </div>
    </div>
  );
}
