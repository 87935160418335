import React from "react";
import Header from "./header";
import Footer from "./footer";

export default function Layout({ children }) {
  const pathname = window.location.pathname;
  let bannerColor;
  switch (pathname) {
    case "/solutions":
      bannerColor = "bg-[#F2CB04]";
      break;
    case "/request-an-estimate":
      bannerColor = "bg-[#ED1589]";
      break;
    case "/contact-us":
      bannerColor = "bg-[#1F66C3]";
      break;
    default:
      bannerColor = "bg-[#42A552]";
      break;
  }

  return (
    <div className="flex min-h-screen flex-col">
      <div className="sticky top-0 z-50 flex flex-col">
        {/*<div className={`font-din py-3 text-sm sm:text-base text-center text-white ${bannerColor}`}>
          <p>SAVE 20% ON BUSINESS CARDS + FREE SHIPPING</p>
        </div>*/}
        <Header />
      </div>
      <div className="flex-1">{children}</div>
      <Footer />
    </div>
  );
}
