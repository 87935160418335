import React from "react";

export default function FAQ() {
  return (
    <div className="pt-16 pb-48 max-w-4xl mx-auto px-4">
      <h2 className="font-din text-[40px] font-bold">Terms and Conditions of Order</h2>
      <div className="font-ibm">
        <p>
          Lawton Connect stands behind the quality of its products and services. If you are not
          satisfied with your purchase from us, simply contact Customer Service in writing within 15
          days from the date you receive it. Lawton Connect is committed to customer satisfaction,
          and we do everything possible to ensure proper cutting, accurate color reproduction, and
          excellent printing quality. However, certain circumstances are beyond our control and are
          not covered by this guarantee. Please note that we cannot be responsible for:
        </p>
        <br />
        <ul className="list-disc pl-5">
          <li>Spelling, punctuation, or grammatical errors made by the customer.</li>
          <li>Inferior quality or low-resolution of uploaded images.</li>
          <li>Design errors introduced by the customer in the document creation process.</li>
          <li>
            Errors in user-selected options such as choice of finish, quantity, or product type.
          </li>
          <li>Damage to the products arising after delivery to the customer.</li>
          <li>
            Incorrect file dimensions, image orientation, or file submission in accordance with
            Lawton Connect specifications.
          </li>
        </ul>
        <br />
        <p className="font-bold font-din">
          This guarantee is also subject to a few other terms and conditions:
        </p>
        <br />
        <ul className="list-disc pl-5">
          <li>
            Lawton Connect reserves the right to have you return the original order, at your
            expense, before reprinting or refunding your order.
          </li>
          <li>
            We will not refund or reprint orders that are delayed due to adverse weather conditions,
            natural disasters, or other acts of God.
          </li>
          <li>
            This guarantee is limited to reprint of the original order quantity or refund of the
            purchase price of the order. We are not responsible for any damages or claims incurred
            by you or your business caused by receiving printed materials that are defective or
            incomplete or that you receive later than the estimated delivery date.
          </li>
          <li>
            This guarantee does not cover fees for mailing services, postage, or associated
            incidental or consequential damages. Lawton Connect is not liable for U.S. Postal
            Service errors, including loss or delay.
          </li>
          <li>
            Lawton Connect is not responsible for any printing errors or problems caused by your
            design decisions. To prevent this, we recommend using our free templates at
            www.lawtonconnect.com/templates, and carefully reviewing your proof in the preview
            section of our site.
          </li>
        </ul>
        <p>
          Please preview your designs carefully and correct any issues prior to placing your order.
        </p>
        <br />
        <p className="font-bold font-din">Color Accuracy and Hardcopy Proofs</p>
        <br />
        <p>
          Lawton Connect will reproduce color from submitted print-ready files as closely as
          possible, but cannot exactly match color and density because of limitations in the
          printing process, as well as neighboring image ink requirements.
        </p>
        <br />
        <p>
          The accuracy of each color reproduction (of the final printed product) is guaranteed to be
          within 90% of the final proof you approved or Lawton Connect will reprint your job at no
          charge to the customer. Please note that Lawton Connect cannot guarantee color
          reproduction for your print-ready files if you did not request a hardcopy proof. Please
          note that we accept no responsibility for color variations between submitted print-ready
          images and the actual artwork or product they represent.
        </p>
        <br />
        <p className="font-bold font-din">
          Our prepress department checks most artwork files before printing, but you are still 100%
          responsible for the accuracy of your print-ready artwork files, and we encourage you to
          proofread all files carefully before submitting to Lawton Connect. As postal regulations
          are subject to change, you are also 100% responsible for complying with current mailing
          restrictions for your layouts. Check with your local Post Office or contact Lawton Connect
          prior to final design. Any changes to the artwork after the first proof has been sent is
          subject to additional fees.
        </p>
        <br />
        <p>
          You certify that you have the right to use the image(s) in your artwork files. DO NOT send
          any “one-of-kind” transparencies, prints or artwork. Although we take every precaution to
          safeguard your materials, we are NOT responsible for loss or damage of images or artwork.
        </p>
        <br />
        <p>
          Under these Terms and Conditions, you agree that you will NOT upload any artwork files
          consisting of the following material: any material that could give rise to any civil or
          criminal liability under applicable law; and any material that could infringe rights of
          privacy, publicity, copyrights or other intellectual property rights without the
          permission of the owner of these rights and the persons who are shown in the material if
          applicable.
        </p>
        <br />
        <p className="font-bold font-din">Printing Turnaround Time</p>
        <br />
        <p>
          Printing turnaround time begins once your order has been placed and your print-ready files
          have been received, proofed, and approved for printing. Lawton Connect will produce based
          on normal production schedule unless noted specifically by the client. Please note that
          Lawton Connect offices are closed Saturdays, Sundays and Holidays. As a result, these days
          are not considered when calculating printing turnaround time. In addition, printing
          turnaround time does not include shipping transit time, and you should allow additional
          business days for delivery based on the shipping method you selected.
        </p>
        <br />
        <p className="font-bold font-din">Target Arrival</p>
        <br />
        <p>
          When requested, target arrival dates are calculated by adding the printing turnaround time
          to the shipping transit time. Please understand that target arrival dates are estimates,
          not guarantees. While we will do everything in our power to meet your target arrival date,
          weather and other occurrences beyond our control may impact our ability to meet that date.
        </p>
        <br />
        <p className="font-bold font-din">Mailing Services</p>
        <br />
        <p>
          Customer&apos;s mailing list(s), while in the possession of Lawton Connect, is the
          exclusive property of the customer and shall be used only with customer&apos;s
          instructions. Customer&apos;s mailing list(s) will not be sold or offered for use to any
          other party, and Lawton Connect will not utilize the list for any other purpose.
        </p>
        <br />
        <p>
          All mailing delivery dates are estimates, not guarantees. Lawton Connect&apos;s
          responsibility is limited to preparing your mailing and completing delivery to the U.S.
          Postal Service (“USPS”). Lawton Connect&apos;s responsibility ends when the job is
          delivered to the Orlando, Florida Business Mail Entry Unit. In no event shall Lawton
          Connect be liable for USPS performance failures or delivery delays.
        </p>
        <br />
        <p>
          Lawton Connect shall be liable to rectify printing and mailing errors only to the extent
          of re-mailing a correction or corrected job as soon as possible, and allowable damages
          shall be limited to the value of the printing work performed. In no case is Lawton Connect
          liable for loss of business; incidental or consequential damages; or costs in excess of
          billing for services related to the specific job.
        </p>
        <br />
        <p>
          The customer will defend and hold Lawton Connect harmless in any suit or court action
          brought against Lawton Connect by others for alleged damages, costs, expenses (including
          reasonable attorney&apos;s fees), liabilities or losses resulting from circumstances where
          Lawton Connect, acting as the customer&apos;s agent, uses copy, photographs, or
          illustrations that are believed by others to be degrading, libelous or harmful to their
          reputations, images, or standing in the community or which in Lawton Connect&apos;s sole
          judgment is an infringement on a trademark, or trade name, or service mark, or copyright
          belonging to others, or in a suit or court action brought against Lawton Connect for
          actions of the customer&apos;s employees which may occur as a result of any mailing.
        </p>
        <br />
        <p>Mailing lists purchased by the customer through Lawton Connect are non-refundable.</p>
        <br />
        <p className="font-bold font-din">Miscellaneous</p>
        <br />
        <p>
          All sales are final, unless otherwise noted. No refunds are given once Lawton
          Connect begins working on your order, which means Lawton Connect has received your
          necessary materials (i.e., digital files and quote approval) and started work on your job
          (i.e., your job is in production).
        </p>
        <br />
        <p>
          Within 30 days of purchase, a partial or full refund may be extended to the customer if no
          work has been completed by Lawton Connect. Please note that such refund would be reduced
          to cover any fees charged for proofs, processing or other services associated with that
          order.
        </p>
        <br />
        <p>
          All materials we create in producing your printed product are the property of Lawton
          Connect. Although these materials will NOT be sold or given to any other party, we reserve
          the right to distribute free samples of your printed product.
        </p>
        <br />
        <br />
        <p className="font-bold font-din text-2xl">TRADE CUSTOMS</p>
        <br />
        <p>QUOTATION: A quotation not accepted within 30 days may be changed.</p>
        <br />
        <p>
          ORDERS: Acceptance of orders is subject to credit approval and contingencies such as
          fires, water, strikes, theft, vandalism, acts of God, and other causes beyond the
          provider’s control. Canceled orders require compensation for incurred costs and related
          obligations.
        </p>
        <br />
        <p>
          ACCURACY OF SPECIFICATIONS: Quotations are based on the accuracy of the specifications
          provided. The provider can re-quote a job at time of submission if copy, film, tapes,
          disks, or other input materials do not conform to the information on which the original
          quotation was based.
        </p>
        <br />
        <p>
          PREPARATORY MATERIALS: Artwork, type, plates, negatives, positives, tapes, disks, and all
          other items supplied by the provider remain the provider’s exclusive property.
        </p>
        <br />
        <p>
          ALTERATIONS/CORRECTIONS: Customer alterations include all work performed in addition to
          the original specifications. All such work will be charged at the provider’s current
          rates.
        </p>
        <br />
        <p>
          PREPRESS PROOFS: The provider will submit prepress proofs along with original copy for the
          customer’s review and approval. Corrections will be returned to the provider on a “master
          set”  marked “O.K.”, “O.K. with corrections”, or “Revised proof required” and signed by
          the customer. Until the master set is received, no additional work will be performed. The
          provider will not be responsible for undetected production errors if: proofs are not
          required by the customer, the work is printed per the customer’s O.K.; and requests for
          changes are communicated orally.
        </p>
        <br />
        <p>
          PRESS PROOFS: Press proofs will not be furnished unless they have been required in writing
          in the provider’s quotation. A press sheet can be submitted for the customer’s approval as
          long as the customer is present at the press during makeready. Any press time lost or
          alterations/corrections made because of the customer’s delay or change of mind will be
          charged at the provider’s current rates.
        </p>
        <br />
        <p>
          OVER-RUNS OR UNDER-RUNS: Over-runs or under-runs will not exceed 10% of the quantity
          ordered. The provider will bill for actual quantity delivered within this tolerance. If
          the customer requires a guaranteed quantity, the percentage of tolerance must be stated at
          the time of quotation.
        </p>
        <br />
        <p>
          CUSTOMER’S PROPERTY: The provider will only maintain fire and extended coverage on
          property belonging to the customer while the property is in the provider’s possession. The
          provider’s liability for this property will not exceed the amount recoverable from the
          insurance. Additional insurance coverage may be obtained if it is requested in writing,
          and if the premium paid the provider.
        </p>
        <br />
        <p>
          DELIVERY: Unless otherwise specified, the price quoted is for a single shipment, without
          storage, F.O.B. provider’s platform. Proposals are based on continuous and uninterrupted
          delivery of the complete order. If the specifications state otherwise, the provider will
          charge accordingly at current rates. Charges for delivery of materials and supplies from
          the customer to the provider, or from the customer’s supplier to the provider, are not
          included in quotations unless specified. Title for finished work passes to the customer
          upon delivery to the carrier at shipping point; or upon mailing of invoices for the
          finished work or its segments, whichever occurs first.
        </p>
        <br />
        <p>
          PRODUCTION SCHEDULES: Production schedules will be established and followed by both the
          customer and the provider. In the event that production schedules are not adhered to by
          the customer, delivery dates will be subject to renegotiation. There will be no liability
          or penalty for delays due to state of war, riot, civil disorder, fire, strikes, accidents,
          action of government or civil authority, acts of God, or other causes beyond the control
          of the provider. In such cases, schedules will be extended by an amount of time equal to
          delay incurred.
        </p>
        <br />
        <p>
          CUSTOMER-FURNISHED MATERIALS: Materials furnished by customers or their suppliers are
          verified by delivery tickets. The provider bears no responsibility for
          discrepancies between delivery tickets and actual counts. Customer-supplied paper must be
          delivered according to specifications furnished by the provider. These specifications will
          include correct weight, thickness, pick resistance, and other technical requirements.
          Artwork, film, color separations, special dies, tapes, disks, or other materials furnished
          by the customer must be usable by the provider without alteration or repair. Items not
          meeting this requirement will be repaired by the customer, or by the provider at the
          provider’s current rates.
        </p>
        <br />
        <p>
          OUTSIDE PURCHASES: Unless otherwise agreed in writing, all outside purchases as requested
          or authorized by the customer, are chargeable.
        </p>
        <br />
        <br />
        <p className="font-bold font-din text-2xl">GENERAL TERMS AND CONDITIONS</p>
        <br />
        <p>
          2. Breach: In the event customer breaches any terms of this contract, customer shall pay
          all costs which printer incurs in enforcing any terms of this contract, including
          a reasonable attorney’s fee, which shall include all costs and attorney’s fees incurred as
          a result of any appeal of a judgement or order relating to enforcement by printer of
          this contract. Any action at law, suit in equity or judicial proceeding for the
          enforcement of this contract or any provision thereof, by either party hereto, shall be
          instituted only in the courts of Orange County, Florida.
        </p>
        <br />
        <p>3. Delivery shall be at the printer’s plant, 649 Triumph Court, Orlando, Florida.</p>
        <br />
        <p>
          4. In the event payment is not made under this agreement as provided in item #1, interest
          on the outstanding balance of the sales price plus charges imposed pursuant to the trade
          customs below, will be at a rate of 11/2% per month (18% per year) until paid in full.
          Each payment when made shall be applied first toward accrued interest and the remainder of
          each payment shall be applied to reduce the unpaid balance.
        </p>
        <br />
        <p>5. This agreement is subject to the Trade Customs below.</p>
        <br />
        <p>
          6. Any conflict between accepted acknowledgement and customer’s purchase order, the
          accepted acknowledgement will govern.
        </p>
        <br />
        <p>
          7. This contract has been delivered in the State of Florida, requires a performance of
          acts in Florida and shall be construed in accordance with the laws of Florida.
        </p>
        <br />
        <p>
          TERMS/CLAIMS/LIENS: Claims for defects, damages, or shortages must be made by the customer
          in writing no later than 15 calendar days after delivery. If no such claim is made, the
          provider and the customer will understand that the job has been accepted. By accepting the
          job, the customer acknowledges that the provider’s performance has fully satisfied all
          terms, conditions, and specifications. The provider’s liability will be limited to the
          quoted selling price of defective goods, without additional liability for special or
          consequential damages. As security for payment of any sum due under the terms of an
          agreement, the provider has the right to hold and place a lien on all customer property in
          the provider’s possession. This right applies even if credit has been extended, notes have
          been accepted, trade acceptances have been made, or payment has been guaranteed. If
          payment is not made, the customer is liable for all collection costs incurred.
        </p>
        <br />
        <p>
          LIABILITY: Disclaimer of Express Warranties: Provider warrants that the work is as
          described in the purchase order. The customer understands that all sketches,
          copy, dummies, and preparatory work shown to the customer are intended only to illustrate
          the general type and quality of the work. They are not intended to represent the
          actual work performed. Disclaimer of Implied Warranties: The provider warrants only that
          the work will conform to the description contained in the purchase order. The
          provider’s maximum liability, whether by negligence, contract, or otherwise, will not
          exceed the return of the amount invoiced for the work in dispute. Under no circumstances
          will the provider be liable for specific, individual, or consequential damages.
        </p>
        <br />
        <p>
          INDEMNIFICATION: The customer agrees to protect the provider from economic loss and any
          other harmful consequences that could arise in connection with the work. This means that
          the customer will hold the provider harmless and save, indemnify, and otherwise defend
          him/her against claims, demands, actions, and proceedings on any and all grounds. This
          will apply regardless of responsibility for negligence. Copyrights: The customer also
          warrants that the subject matter to be printed is not copyrighted by a third party. The
          customer also recognizes that because subject matter does not have to bear a copyright
          notice in order to be protected by copyright law, absence of such notice does not
          necessarily assure a right to reproduce. The customer further warrants that no copyright
          notice has been removed from any material used in preparing the subject matter for
          reproduction. To support these warranties, the customer agrees to indemnify and hold the
          provider harmless for all liability, damages, and attorney fees that may be insured in any
          legal action connected with copyright infringement involving the work produced or
          provided. Personal or economic rights: The customer also warrants that the work does not
          contain anything that is libelous or scandalous, or anything that threatens anyone’s right
          to privacy or other personal or economic rights. The customer will, at the customer’s sole
          expense, promptly and thoroughly defend the provider in all legal actions on these grounds
          as long as the provider: promptly notifies the customer of the legal action, and gives the
          customer reasonable time to undertake and conduct a defense. The provider reserves the
          right to use his or her sole discretion in refusing to print anything he or she deems
          illegal, libelous, scandalous, improper, or infringing upon copyright law.
        </p>
        <br />
        <p>
          STORAGE: The provider will retain intermediate materials until the related end product has
          been accepted by the customer. If requested by the customer, intermediate materials will
          be stored for an additional period at additional charge. The provider is not liable for
          any loss or damage to stored material beyond what is recoverable by the provider’s fire
          and extended insurance coverage.
        </p>
        <br />
        <p>
          TAXES: All amounts due for taxes and assessments will be added to the customer’s invoice
          and are the responsibility of the customer. No tax exemption will be granted unless the
          customer’s “Exemption Certificate” (or other official proof of exemption) accompanies the
          purchase order. If, after the customer has paid the invoice, it is determined that more
          tax is due, then the customer must promptly remit the required taxes to the taxing
          authority, or immediately reimburse the provider for any additional taxes paid.
        </p>
        <br />
        <p>
          TELECOMMUNICATIONS: Unless otherwise agreed, the customer will pay for all transmission
          charges. The provider is not responsible for any errors, omissions, or extra costs
          resulting from faults in the transmission.
        </p>
      </div>
    </div>
  );
}
