import React, {useState} from "react";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import AWS from "aws-sdk";
import { FaSpinner } from 'react-icons/fa';
import formatDateToYearMonthDay from "../utils/format-date";
import {MAX_FILE_SIZE} from "../utils/constants";
window.Buffer = window.Buffer || require("buffer").Buffer;

export default function RequestAnEstimate() {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [cords, setCords] = useState(moment());
  const [preferred_response, setPreferredResponse] = useState('');
  const [message, setMessage] = useState('');
  const [fileUploaded, setFileUploadedStatus] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [totalFileSize, setTotalFileSize] = useState(0);

  const navigate = useNavigate();
  const selectFileButton = (e) => {
    e.preventDefault();
    const file = document.getElementById("file");
    file.click();
  };

  AWS.config.update({
    accessKeyId: `${process.env.REACT_APP_ACCESS_KEY_ID}`,
    secretAccessKey: `${process.env.REACT_APP_SECRET_ACCESS_KEY}`
  })
  const S3_BUCKET = `${process.env.REACT_APP_S3_BUCKET}`;

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: "us-east-1",
  })

  const uploadFile = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // S3 Bucket Name
    if (selectedFiles.length === 0) {
      handleSubmit('');
    } else {
      if (totalFileSize >= MAX_FILE_SIZE) {
        setIsLoading(false);
        setErrorText('Total file size exceeds 1 Gigabyte.');
        return;
      }
      const formattedDate = formatDateToYearMonthDay(new Date())
      const filePath = `uploads/${company.replace(/\s+/g, '').toLowerCase()}-${formattedDate}`;

      const uploadPromises = selectedFiles.map(file => {
        const params = {
          ACL: 'public-read',
          Bucket: S3_BUCKET,
          Key: `${filePath}/` + file.name,
          Body: file,
        };

        return myBucket.putObject(params).promise()
      });

      try {
        await Promise.all(uploadPromises);
        handleSubmit(filePath);
      } catch (error) {
        setIsLoading(false);
        setErrorText(`One or more uploads failed: ${error}`);
      }
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'name') setName(value);
    if (name === 'email') setEmail(value);
    if (name === 'phone') setPhone(value);
    if (name === 'company') setCompany(value);
    if (name === 'address') setAddress(value);
    if (name === 'cords') setCords(value);
    if (name === 'preferred_response') setPreferredResponse(value);
    if (name === 'message') setMessage(value);
  };

  const handleSubmit = async (filePath) => {
    apiCall(filePath);
  };

  const apiCall = async (filePath) => {
    const serverEndpoint = `${process.env.REACT_APP_BASE_API_URL}/contacts`;

    const source = "quote"

    // Form data to be submitted
    const formData = {name, email, phone, company, address, cords, preferred_response, message, source, filePath};

    try {
      const response = await fetch(serverEndpoint, {
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          'Site': `${process.env.REACT_APP_SITE_ID}`
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setIsLoading(false);
        // Form submitted successfully, redirect to another page
        navigate('/thank-you'); // Redirect to a Thank You page, for example
      } else {
        setIsLoading(false);
        // Handle server errors or invalid responses
        setErrorText(`An error occurred while submitting the form. Please try again later : ${response}`);
        console.error('Server error:', response);
      }
    } catch (error) {
      setIsLoading(false);
      // Handle network errors
      setErrorText(`A network error occurred while submitting the form. Please check your internet connection and try again: ${error}.`);
      console.error('Network error:', error);
    }
  }
  const handleFileChange = (event) => {
    event.preventDefault();
    const files = event.target.files;
    let totalSize = 0;
    setSelectedFiles(Array.from(event.target.files));
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        totalSize += files[i].size;
      }
    }

    //const totalSizeInKb = totalSize;
    setTotalFileSize(totalSize);
  };

  return (
    <div>
      <div className="container py-16 gap-8 flex justify-between flex-wrap">
        <div className="max-w-md">
          <h2 className="font-din text-[40px]">Get A Quote</h2>
          <p className="font-ibm text-lg font-light">
            Whether your project is ready for
            production or still in sketches at the
            moment, this is where Lawton Connect
            begins. Just submit your project
            information in the form and upload
            artwork if available.
            <br/> <br/>
            We look forward to partnering
            together. A member of our team will
            connect with you soon.
          </p>
        </div>
        <div className="max-w-2xl flex-1 font-ibm text-sm mt-10">
          { errorText && <p className="text-red-500 text-center">{errorText}</p> }
          <p className="mb-5">Fields marked with an asterisk (*) are required.</p>
          <form onSubmit={uploadFile} encType="multipart/form-data" className="flex flex-col space-y-1.5" acceptCharset="UTF-8">
            <div className="flex items-center justify-between flex-wrap gap-2">
              <label className="w-[150px]" htmlFor="name">
                Name *
              </label>
              <input
                  required={true}
                  onChange={handleInputChange}
                type="text"
                name="name"
                id="name"
                className="rounded w-[250px] flex-1 bg-transparent sm:max-w-lg px-3 py-1.5 min-w-[350px] outline-none border-[0.5px] border-black"
              />
            </div>
            <div className="flex items-center justify-between flex-wrap gap-2">
              <label className="w-[150px]" htmlFor="company">
                Company *
              </label>
              <input
                  required={true}
                  onChange={handleInputChange}
                type="text"
                name="company"
                id="company"
                className="rounded w-[250px] bg-transparent flex-1 sm:max-w-lg px-3 py-1.5 min-w-[350px] outline-none border-[0.5px] border-black"
              />
            </div>
            <div className="flex items-center justify-between flex-wrap gap-2">
              <label className="w-[150px]" htmlFor="address">
                Address *
              </label>
              <input
                  required={true}
                  onChange={handleInputChange}
                type="text"
                name="address"
                id="address"
                className="rounded w-[250px] bg-transparent flex-1 sm:max-w-lg px-3 py-1.5 min-w-[350px] outline-none border-[0.5px] border-black"
              />
            </div>
            <div className="flex items-center justify-between flex-wrap gap-2">
              <label className="w-[150px]" htmlFor="cords">
                City, State, Zip *
              </label>
              <input
                  required={true}
                  onChange={handleInputChange}
                type="text"
                name="cords"
                id="cords"
                className="rounded w-[250px] bg-transparent flex-1 sm:max-w-lg px-3 py-1.5 min-w-[350px] outline-none border-[0.5px] border-black"
              />
            </div>
            <div className="flex items-center justify-between flex-wrap gap-2">
              <label className="w-[150px]" htmlFor="phone">
                Phone *
              </label>
              <input
                  required={true}
                  onChange={handleInputChange}
                type="text"
                name="phone"
                id="phone"
                className="rounded w-[250px] bg-transparent flex-1 sm:max-w-lg px-3 py-1.5 min-w-[350px] outline-none border-[0.5px] border-black"
              />
            </div>
            <div className="flex items-center justify-between flex-wrap gap-2">
              <label className="w-[150px]" htmlFor="email">
                Email *
              </label>
              <input
                  required={true}
                  onChange={handleInputChange}
                type="text"
                name="email"
                id="email"
                className="rounded w-[250px] bg-transparent flex-1 sm:max-w-lg px-3 py-1.5 min-w-[350px] outline-none border-[0.5px] border-black"
              />
            </div>
            <div className="flex items-center justify-between flex-wrap gap-2">
              <label className="w-[150px]" htmlFor="preferred-response">
                Preferred Response
              </label>
              <input
                  onChange={handleInputChange}
                type="text"
                name="preferred_response"
                id="preferred-response"
                className="rounded bg-transparent flex-1 sm:max-w-lg px-3 py-1.5 min-w-[350px] outline-none border-[0.5px] border-black"
              />
            </div>
            <div className="flex items-start justify-between flex-wrap gap-2">
              <label className="w-[150px]" htmlFor="project-notes">
                Project Notes
              </label>
              <textarea
                  onChange={handleInputChange}
                type="text"
                name="message"
                rows={3}
                id="project-notes"
                className="rounded bg-transparent flex-1 sm:max-w-lg px-3 py-1.5 min-w-[350px] outline-none border-[0.5px] border-black"
              ></textarea>
            </div>
            <div className="ml-auto max-w-lg flex justify-between w-full !mt-8">
              <input type="file" name="files" className="hidden" id="file" accept=".pdf, .zip, .rar" multiple
                     onChange={handleFileChange} />
              <button onClick={selectFileButton}
                      className="rounded-full font-d-din-normal bg-[#88B6F2] text-white py-3 px-12">
                <label htmlFor="file" className="cursor-pointer">
                  SELECT FILE
                </label>
              </button>
              <button className="rounded-full font-d-din-normal bg-black text-white py-3 px-12" disabled={isLoading}>
                {isLoading && <FaSpinner className="animate-spin"/>}
                {isLoading ? 'LOADING...' : 'SEND REQUEST'}
              </button>
            </div>
            <div>
              {totalFileSize >= MAX_FILE_SIZE && (
                  <p className="text-sm text-red-500">Total file size exceeds 1 Gigabyte.</p>
              )}
              <ul>
                {selectedFiles.map((file, index) => (
                    <li key={index}>{file.name}</li>
                ))}
              </ul>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
