import React, {useState} from "react";
import moment from "moment/moment";
import {useNavigate} from "react-router-dom";
import AWS from "aws-sdk";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import S3 from "react-aws-s3";
import {FaSpinner} from "react-icons/fa";
import formatDateToYearMonthDay from "../utils/format-date";
import {MAX_FILE_SIZE} from "../utils/constants";

export default function SendAFile() {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [phone, setPhone] = useState('');
  const [due_date, setDueDate] = useState(new Date());
  const [message, setMessage] = useState('');
  const [project_name, setProjectName] = useState('');
  const [estimate_number, setEstimateNumber] = useState('');
  const [errorText, setErrorText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [totalFileSize, setTotalFileSize] = useState(0);

  const navigate = useNavigate();
  const selectFileButton = (e) => {
    e.preventDefault();
    const file = document.getElementById("file");
    file.click();
  };

  AWS.config.update({
    accessKeyId: `${process.env.REACT_APP_ACCESS_KEY_ID}`,
    secretAccessKey: `${process.env.REACT_APP_SECRET_ACCESS_KEY}`
  })
  const S3_BUCKET = `${process.env.REACT_APP_S3_BUCKET}`;

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: "us-east-1",
  })

  const uploadFile = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // S3 Bucket Name
    if (selectedFiles.length === 0) {
      handleSubmit('');
    } else {
      if (totalFileSize >= MAX_FILE_SIZE) {
        setIsLoading(false);
        setErrorText('Total file size exceeds 1 Gigabyte.');
        return;
      }
      const formattedDate = formatDateToYearMonthDay(new Date())
      const filePath = `uploads/${company.replace(/\s+/g, '').toLowerCase()}-${formattedDate}`;

      const uploadPromises = selectedFiles.map(file => {
        const params = {
          ACL: 'public-read',
          Bucket: S3_BUCKET,
          Key: `${filePath}/` + file.name,
          Body: file,
        };

        return myBucket.putObject(params).promise()
      });

      try {
        await Promise.all(uploadPromises);
        handleSubmit(filePath);
      } catch (error) {
        setIsLoading(false);
        setErrorText(`One or more uploads failed: ${error}`);
      }
    }
  };

  const handleSubmit = async (filePath) => {
    apiCall(filePath);
  };

  const apiCall = async (filePath) => {
    const serverEndpoint = `${process.env.REACT_APP_BASE_API_URL}/contacts`;

    const source = "send_a_file"

    // Form data to be submitted
    const formData = {name, email, phone, company, project_name, estimate_number, due_date, message, source, filePath};

    try {
      const response = await fetch(serverEndpoint, {
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          'Site': `${process.env.REACT_APP_SITE_ID}`
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // Form submitted successfully, redirect to another page
        setIsLoading(false);
        navigate('/thank-you'); // Redirect to a Thank You page, for example
      } else {
        // Handle server errors or invalid responses
        setIsLoading(false);
        setErrorText(`An error occurred while submitting the form. Please try again later : ${response}`);
        console.error('Server error:', response);
      }
    } catch (error) {
      // Handle network errors
      setIsLoading(false);
      setErrorText(`A network error occurred while submitting the form. Please check your internet connection and try again: ${error}.`);
      console.error('Network error:', error);
    }
  }
  const handleFileChange = (event) => {
    event.preventDefault();
    const files = event.target.files;
    let totalSize = 0;
    setSelectedFiles(Array.from(event.target.files));
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        totalSize += files[i].size;
      }
    }

    //const totalSizeInKb = totalSize;
    setTotalFileSize(totalSize);
  };


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'name') setName(value);
    if (name === 'email') setEmail(value);
    if (name === 'phone') setPhone(value);
    if (name === 'company') setCompany(value);
    if (name === 'project_name') setProjectName(value);
    if (name === 'est_number') setEstimateNumber(value);
    if (name === 'message') setMessage(value);
  };

  return (
    <div className="bg-white">
      <div className="container py-16 gap-8 flex justify-between flex-wrap">
        <div className="max-w-md">
          <h2 className="font-din text-[40px]">Upload Files</h2>
          <p className="font-ibm text-lg font-light">
            You can easily send a file from your computer to Lawton Connect in a matter of minutes.
            Just enter your information in the provided spaces, please upload your uniquely named{" "}
            <span className="font-bold">PDF</span> or{" "}
            <span className="font-bold">zipped files</span> (if sending multiple files), click
            submit, and we&apos;ll send you an e-mail to confirm that we&apos;ve received it.
            It&apos;s that easy to connect.
            <br />
            <br />
            If you experience issues uploading files, please use WeTransfer or Dropbox.
          </p>
        </div>
        <div className="max-w-2xl mt-10 flex-1 font-ibm text-sm">
          {errorText && <p className="text-red-500 text-center">{errorText}</p>}
          <p className="mb-5">Fields marked with an asterisk (*) are required.</p>
          <form onSubmit={uploadFile} className="flex flex-col space-y-1.5">
            <div className="flex items-center justify-between flex-wrap gap-2">
              <label className="w-[150px]" htmlFor="name">
                Name *
              </label>
              <input
                  required={true}
                  onChange={handleInputChange}
                  type="text"
                  name="name"
                  id="name"
                  className="rounded w-[250px] flex-1 bg-transparent sm:max-w-lg px-3 py-1.5 min-w-[350px] outline-none border-[0.5px] border-black"
              />
            </div>
            <div className="flex items-center justify-between flex-wrap gap-2">
              <label className="w-[150px]" htmlFor="company">
                Company *
              </label>
              <input
                  required={true}
                  onChange={handleInputChange}
                  type="text"
                  name="company"
                  id="company"
                  className="rounded w-[250px] bg-transparent flex-1 sm:max-w-lg px-3 py-1.5 min-w-[350px] outline-none border-[0.5px] border-black"
              />
            </div>
            <div className="flex items-center justify-between flex-wrap gap-2">
              <label className="w-[150px]" htmlFor="phone">
                Phone *
              </label>
              <input
                  required={true}
                  onChange={handleInputChange}
                  type="text"
                  name="phone"
                  id="phone"
                  className="rounded w-[250px] bg-transparent flex-1 sm:max-w-lg px-3 py-1.5 min-w-[350px] outline-none border-[0.5px] border-black"
              />
            </div>
            <div className="flex items-center justify-between flex-wrap gap-2">
              <label className="w-[150px]" htmlFor="email">
                Email *
              </label>
              <input
                  required={true}
                  onChange={handleInputChange}
                  type="text"
                  name="email"
                  id="email"
                  className="rounded w-[250px] bg-transparent flex-1 sm:max-w-lg px-3 py-1.5 min-w-[350px] outline-none border-[0.5px] border-black"
              />
            </div>
            <div className="flex items-center justify-between flex-wrap gap-2">
              <label className="w-[150px]" htmlFor="project-name">
                Project Name
              </label>
              <input
                  onChange={handleInputChange}
                  type="text"
                  name="project_name"
                  id="project-name"
                  className="rounded bg-transparent flex-1 sm:max-w-lg px-3 py-1.5 min-w-[350px] outline-none border-[0.5px] border-black"
              />
            </div>
            <div className="flex items-start justify-between flex-wrap gap-2">
              <label className="w-[150px]" htmlFor="due-date">
                Desired Due Date
              </label>
              <div
                  className="rounded bg-transparent flex-1 sm:max-w-lg px-3 py-1.5 min-w-[350px] outline-none border-[0.5px] border-black">
                <DatePicker
                    selected={due_date}
                    onChange={(date) => setDueDate(date)}
                    startDate={new Date()}
                    minDate={new Date()}
                    scrollableYearDropdown={true}
                    dropdownMode="select"
                    className="border-0 min-w-[350px] outline-none"
                    // Remove className from here if it's not applying as expected
                />
              </div>
            </div>
            <div className="flex items-start justify-between flex-wrap gap-2">
              <label className="w-[150px]" htmlFor="est-num">
                Estimate Number
              </label>
              <input
                  onChange={handleInputChange}
                  type="text"
                  name="est_number"
                  id="est-num"
                  className="rounded bg-transparent flex-1 sm:max-w-lg px-3 py-1.5 min-w-[350px] outline-none border-[0.5px] border-black"
              />
            </div>
            <div className="flex items-start justify-between flex-wrap gap-2">
              <label className="w-[150px]" htmlFor="add-details">
                Additional Details
              </label>
              <textarea
                  onChange={handleInputChange}
                  type="text"
                  name="message"
                  rows={3}
                  id="add-details"
                  className="rounded bg-transparent flex-1 sm:max-w-lg px-3 py-1.5 min-w-[350px] outline-none border-[0.5px] border-black"
              ></textarea>
            </div>
            <div className="ml-auto max-w-lg flex justify-between w-full !mt-8">
              <input onChange={handleFileChange} type="file" className="hidden" id="file" accept=".pdf, .zip, .rar"
                     multiple/>
              <button onClick={selectFileButton}
                      className="rounded-full font-d-din-normal bg-[#42A552] text-white py-3 px-12">
                <label htmlFor="file" className="cursor-pointer">
                  SELECT FILES
                </label>
              </button>
              <button className="rounded-full font-d-din-normal bg-black text-white py-3 px-12" disabled={isLoading}>
                {isLoading && <FaSpinner className="animate-spin"/>}
                {isLoading ? 'LOADING...' : 'UPLOAD FILES'}
              </button>
            </div>
            <div>
              {totalFileSize >= MAX_FILE_SIZE  && (
                  <p className="text-sm text-red-500">Total file size exceeds 1 Gigabyte.</p>
              )}
              <ul>
                {selectedFiles.map((file, index) => (
                    <li key={index}>{file.name}</li>
                ))}
              </ul>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
