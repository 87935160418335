export default function formatDateToYearMonthDay(date) {
    // Create a new Date object
    const formattedDate = new Date(date);

    // Get the year, month, and day components
    const year = formattedDate.getFullYear();
    const month = ('0' + (formattedDate.getMonth() + 1)).slice(-2); // Months are zero-based, so we add 1
    const day = ('0' + formattedDate.getDate()).slice(-2);

    // Concatenate the components into the desired format
    const formattedDateString = `${year}-${month}-${day}`;

    return formattedDateString;
}