import React, {useEffect, useRef, useState} from "react";
import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import lightGallery from "lightgallery";

export default function Portfolio() {
  const galleryRef = useRef(null);

  const [imageGroups] = useState([
    {
      title: "Brochures",
      thumb: './brochures.png',
      images: [
        { src: './brochures.png', thumb: './brochures.png' },
        { src: './brochure-alternate-1.jpg', thumb: './brochure-alternate-1.jpg' },
        { src: './brochure-alternate-2.jpg', thumb: './brochure-alternate-2.jpg' },
        { src: './brochure-alternate-3.jpg', thumb: './brochure-alternate-3.jpg' },
      ]
    },
    {
      title: "Pocket Folders",
      thumb: './pocket_folders.png',
      images: [
        { src: './pocket_folders.png', thumb: './pocket_folders.png' },
        { src: './folder-alternate-1.jpg', thumb: './folder-alternate-1.jpg' },
      ]
    },
    {
      title: "Menus",
      thumb: './menus.png',
        images: [
            { src: './menus.png', thumb: './menus.png' },
            { src: './menu-alternate-1.jpg', thumb: './menu-alternate-1.jpg' },
        ]
    },
    {
      title: "Magazines",
      thumb: './Rectangle 47.jpg',
      images: [
        { src: './Rectangle 47.jpg', thumb: './Rectangle 47.jpg' },
        { src: './magazine-alternate-1.jpg', thumb: './magazine-alternate-1.jpg' },
        { src: './magazine-alternate-2.jpg', thumb: './magazine-alternate-2.jpg' },
        { src: './magazine-alternate-3.jpg', thumb: './magazine-alternate-3.jpg' },
        { src: './magazine-alternate-4.jpg', thumb: './magazine-alternate-4.jpg' },
      ]
    },
    {
      title: "Boxes/POP Display",
      thumb: './Rectangle 48.jpg',
      images: [
        {src: './Rectangle 48.jpg', thumb: './Rectangle 48.jpg'},
        { src: './POP-alternate-1.jpg', thumb: './POP-alternate-1.jpg' },
        { src: './POP-alternate-2.jpg', thumb: './POP-alternate-2.jpg' },
        { src: './POP-alternate-3.jpg', thumb: './POP-alternate-3.jpg' },
      ]
    },
    {
      title: "Business Cards",
      thumb: './cards.png',
      images: [
        { src: './cards.png', thumb: './cards.png' },
        { src: './biz-card-alternate-1.jpg', thumb: './biz-card-alternate-1.jpg' },
        { src: './biz-card-alternate-2.jpg', thumb: './biz-card-alternate-2.jpg' },
        { src: './biz-card-alternate-3.jpg', thumb: './biz-card-alternate-3.jpg' },
      ]
    },
    {
      title: "Flyers",
      thumb: './Rectangle 54.jpg',
      images: [
        { src: './Rectangle 54.jpg', thumb: './Rectangle 56.jpg' },
        { src: './sheetfed-alternate-1.jpg', thumb: './sheetfed-alternate-1.jpg' },
        { src: './sheetfed-alternate-2.jpg', thumb: './sheetfed-alternate-2.jpg' },
      ]
    },
    {
      title: "Signs/Banners",
      thumb: './Rectangle 56.jpg',
      images: [
        { src: './Rectangle 56.jpg', thumb: './Rectangle 56.jpg' },
        { src: './wide-format-alternate-1.jpg', thumb: './wide-format-alternate-1.jpg' },
        { src: './wide-format-alternate-2.jpg', thumb: './wide-format-alternate-2.jpg' },
        { src: './wide-format-alternate-3.jpg', thumb: './wide-format-alternate-3.jpg' },
      ]
    },
    {
      title: "Cards/Stationery",
      thumb: './Rectangle 57.jpg',
      images: [
        { src: './Rectangle 57.jpg', thumb: './Rectangle 57.jpg' },
        { src: './stationary-alternate-1.jpg', thumb: './stationary-alternate-1.jpg' },
        { src: './specialty-promo-alternate-3.jpg', thumb: './specialty-promo-alternate-3.jpg' },
      ]
    },
    {
      title: "Scratch Off",
      thumb: './Rectangle 53.jpg',
      images: [
        { src: './Rectangle 53.jpg', thumb: './Rectangle 53.jpg' },
        { src: './specialty-promo-alternate-2.jpg', thumb: './specialty-promo-alternate-2.jpg' },
        { src: './specialty-promo-alternate-4.jpg', thumb: './specialty-promo-alternate-4.jpg' },
      ]
    }
  ]);

  useEffect(() => {
    // Initialize LightGallery
    const lgInstance = lightGallery(galleryRef.current, {
      plugins: [lgThumbnail, lgFullscreen],
      dynamic: true,
      dynamicEl: [],
      thumbnail: true,
      mode: 'lg-fade'
    });

    return () => {
      if (lgInstance) {
        lgInstance.destroy();
      }
    };
  }, []);

  const showGallery = (images) => {
    const dynamicEl = images.map(image => ({
      src: image.src,
      thumb: image.thumb
    }));

    // Reinitialize LightGallery with new dynamic elements
    lightGallery(galleryRef.current, {
      dynamic: true,
      dynamicEl: dynamicEl,
      thumbnail: true,
    }).openGallery();
  };

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        const offset = 80; // Offset value in pixels
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition - offset;
        window.scrollBy({ top: offsetPosition, behavior: 'smooth' });
      }
    }
  }, []);

  return (
      <div>
        <div className="gap-4 2xl:gap-16 relative bg-white flex flex-col lg:flex-row lg:items-center flex-wrap">
          <div className="sm:pl-16 order-2 lg:order-1 py-12 leading-snug px-4">
            <h1 className="text-[40px] font-din mb-3">Portfolio</h1>
            <p className="max-w-xs text-lg font-ibm font-light">
              We have had the pleasure of working with some of the most iconic brands
              in the world. Our diverse print capabilities allow us to support the most
              basic print needs through the most innovative design ideas.
            </p>
          </div>
          <div className="order-1 lg:order-2 flex-1">
            <img src="./portfolio.png" alt="" className="max-h-[450px] object-cover w-full"/>
          </div>
        </div>

        <div id="lightGallery" className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 container py-14 gap-x-4 gap-y-14">
          {imageGroups.map((group, index) => (
              <div key={index} className="flex flex-col items-center gap-3.5">
                <img
                    className="h-[235px] w-full border border-gray-400"
                    src={group.thumb}
                    alt={`Thumbnail ${index + 1}`}
                    onClick={() => showGallery(group.images)}
                    style={{cursor: 'pointer', margin: '10px'}}
                />
                <span className="font-bold font-din">{group.title}</span>
              </div>
          ))}
          <div ref={galleryRef} style={{display: 'none'}}></div>
        </div>

        <div className="bg-[#F2CB04] py-10" id="testimonials">
          <div className="container">
            <h2 className="font-din text-[40px] text-center">Testimonials</h2>
            <hr className="border-[#5A5A5A] my-4 w-[984px] mx-auto"/>
            <div className="flex justify-center gap-x-32 gap-y-12 flex-wrap font-ibm font-light mt-10">
              <div>
                <p className="leading-snug max-w-sm text-lg">
                  We highly recommend Lawton Connect. The
                  service quality is truly exceptional – no job is too
                  big or too small.We rely on their printing services
                  and trust our jobs will arrive on time and within
                  budget. The team is extremely knowledgeable
                  and helpful, it is a delight to work with them.
                </p>
                <p className="leading-snug ml-auto w-fit text-lg mt-4">— Lydia, Conscious Discipline</p>
              </div>
              <div>
                <p className="leading-snug max-w-sm text-lg">
                  Thank you so much for your outstanding customer service and getting these done so
                  quickly. I&apos;ll definitely keep you in mind for future orders!
                </p>
                <p className="leading-snug ml-auto w-fit text-lg mt-4">
                  — Cheryl, Southwest Airlines
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}
