import React from "react";
import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import Home from "./pages/home";
import Layout from "./components/layout";
import WhoWeAre from "./pages/who-we-are";
import Portfolio from "./pages/portfolio";
import Solutions from "./pages/solutions";
import RequestAnEstimate from "./pages/request-an-estimate";
import SendAFile from "./pages/send-a-file";
import ContactUs from "./pages/contact-us";
import FAQ from "./pages/faq";
import ThankYou from "./pages/thank-you";
import { useLocation } from "react-router-dom";
import PrivacyPolicy from "./pages/privacy-policy";
import Sitemap from "./components/sitemap";
import Robots from "./components/robots";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          path="/who-we-are"
          element={
            <Layout>
              <WhoWeAre />
            </Layout>
          }
        />
        <Route
          path="/portfolio"
          element={
            <Layout>
              <Portfolio />
            </Layout>
          }
        />
        <Route
          path="/solutions"
          element={
            <Layout>
              <Solutions />
            </Layout>
          }
        />
        <Route
          path="/request-an-estimate"
          element={
            <Layout>
              <RequestAnEstimate />
            </Layout>
          }
        />
        <Route
          path="/send-a-file"
          element={
            <Layout>
              <SendAFile />
            </Layout>
          }
        />
        <Route
          path="/contact-us"
          element={
            <Layout>
              <ContactUs />
            </Layout>
          }
        />
        <Route
          path="/faq"
          element={
            <Layout>
              <FAQ />
            </Layout>
          }
        />
        <Route
          path="/thank-you"
          element={
            <Layout>
              <ThankYou />
            </Layout>
          }
        />
          <Route
              path="/privacy-policy"
              element={
                <Layout>
                  <PrivacyPolicy />
                </Layout>
              }
          />
      </Routes>
    </Router>
  );
}
export default App;
