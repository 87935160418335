import React, {useEffect} from "react";
import { MoveRight } from "lucide-react";

export default function Solutions() {

  useEffect(() => {
    const handleScroll = (event) => {
      const targetId = event.target.getAttribute('href');
      if (targetId && targetId.startsWith('#')) {
        event.preventDefault();
        const targetElement = document.querySelector(targetId);
        if (targetElement) {
          const offset = targetId === "#direct_mail" ? 150 : 80;
          //const offset = 90; // Offset value in pixels
          const elementPosition = targetElement.getBoundingClientRect().top;
          const offsetPosition = elementPosition - offset;

          window.scrollBy({
            top: offsetPosition,
            behavior: 'smooth',
          });
        }
      }
    };

    const links = document.querySelectorAll('a[href^="#"]');
    links.forEach((link) => {
      link.addEventListener('click', handleScroll);
    });

    return () => {
      links.forEach((link) => {
        link.removeEventListener('click', handleScroll);
      });
    };
  }, []);


  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        //const offset = 50; // Offset value in pixels
        const elementId = element.getAttribute('id');
        const offset = elementId === "direct_mail" ? 120 : 80;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition - offset;
        window.scrollBy({ top: offsetPosition, behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <div>
      <div className="flex bg-white flex-col lg:flex-row order-2 lg:order-1 flex-wrap">
        <div className="order-2 lg:order-1 sm:pl-16 mr-16 leading-loose mt-10">
          <h1 className="text-[40px] font-din mb-3">Solutions</h1>
          <p className="max-w-md mb-5 text-lg font-light font-ibm">
            Lawton Connect helps you make the right first
            impression. Our ongoing investments in
            equipment and technology provide flexibility
            for projects of virtually any quantity and any
            budget.
          </p>
          <p className="max-w-md text-lg text-[#090909] font-ibm font-light">
            We don’t shy away from challenges. We run
            toward them with absolute determination,
            because when we connect our experience,
            technology, team, and immeasurable
            resources, the impossible suddenly becomes reality.
          </p>
        </div>
        <div className="order-1 lg:order-2 flex-1">
          <img src="./solutions.png" alt="" className="h-[471px] object-cover w-full" />
        </div>
      </div>
      <div className="container pt-10 pb-16">
        <div className="flex justify-evenly mb-1 font-ibm sm:text-lg text-[#434F61]">
          <div><a href="#printing">Sheetfed Printing</a></div>
          <div><a href="#wide_format">Wide Format</a></div>
          <div><a href="#menu_printing">Menu Printing</a></div>
          <div><a href="#speciality">Specialty</a></div>
          <div><a href="#direct_mail">Direct Mail and Fulfillment</a></div>
        </div>
        <hr className="border-[#5A5A5A] w-[1060px] mx-auto" />
      </div>
      <div id="printing">

      </div>
      <div className="py-16 bg-[url('../public/sheetfed_printing.png')] bg-cover">
        <div className="container">
          <div className="mr-auto max-w-lg shadow p-12 bg-white">
            <span className="block mb-3 font-din text-3xl font-bold">Sheetfed Printing</span>

            <p className="font-ibm max-w-sm">
              Lawton Connect utilizes the latest technology in our conventional offset
              presses with LED-UV inks and lamps to deliver the most vibrant and finest
              finished products. Our highly trained team of experts are perfectionists
              who truly love the art and science of print.
              <br />
              <br />
              Our HP Indigo digital printing presses feature a wide array of customized
              digital options for efficiently brilliant results. Its versatility includes white ink
              and digital metallic ink, accommodates up to a B2 sheet size with 24-point
              board, and option for personalized text and variable images.
              <br />
              <br />
              And we take pride in our strong finishing and have made significant
              investments to bring it all in-house including diecut, foil and emboss,
              lamination, spiral binding, digital foil sleeking, saddle-stitching, and more.
            </p><br/><br/><br/>
            <a href="/request-an-estimate" className="rounded-full bg-[#ED1589] transition hover:opacity-80 px-10 py-2 text-lg mt-24 tracking-wide text-white">
              GET A QUOTE
            </a>
          </div>
        </div>
      </div>
      <div id="wide_format"></div>
      <div></div>
      <div className="py-16">
        <div className="container flex flex-wrap gap-10">
          <div className="shadow">
            <img src="./wide_format.png" alt="" className="max-w-[760px]" />
          </div>
          <div className="max-w-md">
            <span className="block mb-3 font-din text-3xl font-bold">Wide Format</span>
            <p className="font-ibm mb-3.5">
              At Lawton Connect, wide-format means wide variety. Our large format equipment uses
              Latex and UV inks on substrates up to 98 inches wide and 2.5 inches thick. Our
              experienced team can combine wide-format printing with conventional, offset, and
              digital printed pieces to streamline fulfillment, color consistency, and messaging.
            </p>
            <div>
              <span>Our wide-format capabilities include:</span>
              <ul className="list-disc pl-5">
                <li>POP & Retail Displays</li>
                <li>Trade Show & Event Graphics</li>
                <li>Banners & Informational Signs</li>
                <li>Vehicle Graphics</li>
                <li>Yard Signs, Traffic Signs, Transit Signage</li>
                <li>Building Wraps & Fence Wraps</li>
                <li>Digital Fine Art and Photo Reproduction</li>
                <li>Museum Graphics</li>
                <li>Packaging & Temporary Displays</li>
                <li>Backlit Graphics</li>
                <li>Posters and much more</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <hr className="border-[#5A5A5A]" />
      </div>
      <div className="py-16" id="menu_printing">
        <div className="container flex flex-wrap gap-x-12 gap-y-8">
          <div className="max-w-md">
            <span className="block mb-3 font-din text-3xl mt-5">Menu Printing</span>
            <p className="font-ibm mb-3.5">
              Lawton Connect provides solutions for the restaurant industry&apos;s high quality
              marketing and print needs. With our in-house expertise, we can help automate the menu
              creation process. Through our online systems, we can reduce your costs, increase your
              accuracy, and allow you to get to market faster.
            </p>
            <p className="font-ibm mb-3.5">
              At Lawton Connect, we have experts in the restaurant industry along with being a
              knowledgeable, flexible, and innovative partner. We work to provide a customized
              approach to solves your challenges as our success is directly connected to yours.
            </p>
            <div>
              <span>Restaurant Solutions can include:</span>
              <ul className="list-disc pl-5">
                <li>Menu printing</li>
                <li>Automated menu work-flows</li>
                <li>Online ordering (web to print storefronts)</li>
                <li>Delivery Solutions (fulfillment, pick/pack, kitting or bulk inventory)</li>
                <li>Menu boards (indoor and drive thru)</li>
                <li>Restaurant signage</li>
                <li>Customer Loyalty Programs</li>
                <li>Direct billing to franchisees</li>
              </ul>
            </div>
          </div>
          <div>
            <img src="./menu_printing_2.png" alt="" className="max-h-[650px] h-full w-[750px]" />
          </div>
        </div>
      </div>
      <div className="bg-[#1F66C3] py-10" id="speciality">
        <div className="container">
          <h3 className="font-din text-white text-[40px] text-center">Specialty</h3>
          <p className="font-ibm text-xl text-white text-center max-w-2xl mx-auto">
            Lawton Connect can help create a custom experience for your clients,
            prospects, and fans with products that connect you with your audience
            in compelling new ways.
          </p>
          <hr className="border-white my-12 w-[900px] mx-auto" />
          <div className="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-10">
            <div className="bg-white shadow-sm">
              <img src="./sp1.jpg" alt="" className="object-cover w-full h-[230px]" />
              <div className="px-7 pt-2.5 pb-5">
                <span className="font-din mb-2 block text-xl">Scratch Off Printing</span>
                <a href="https://mrscratchoff.com" target="_blank" className="group flex items-center space-x-3 uppercase">
                  <span className="transition group-hover:opacity-80 font-d-din text-[#4D4D4D]">
                    Learn More
                  </span>
                  <MoveRight className="text-[#4D4D4D] transition group-hover:translate-x-3" />
                </a>
              </div>
            </div>
            <div className="bg-white shadow-sm">
              <img src="./sp2.jpg" alt="" className="object-cover w-full h-[230px]" />
              <div className="px-7 pt-2.5 pb-5">
                <span className="font-din mb-2 block text-xl">Playing Card Printing</span>
                <a href="https://mrplayingcard.com" target="_blank" className="group flex items-center space-x-3 uppercase">
                  <span className="transition group-hover:opacity-80 font-d-din-normal text-[#4D4D4D]">
                    Learn More
                  </span>
                  <MoveRight className="text-[#4D4D4D] transition group-hover:translate-x-3" />
                </a>
              </div>
            </div>
            <div className="bg-white shadow-sm">
              <img src="./promo_product.png" alt="" className="object-cover w-full h-[230px]" />
              <div className="px-7 pt-2.5 pb-5">
                <span className="font-din mb-2 block text-xl">Promotional Products</span>
                <a href="https://lawtonconnect.espwebsite.com" target="_target" className="group flex items-center space-x-3 uppercase">
                  <span className="transition group-hover:opacity-80 font-d-din text-[#4D4D4D]">
                    Learn More
                  </span>
                  <MoveRight className="text-[#4D4D4D] transition group-hover:translate-x-3" />
                </a>
              </div>
            </div>
            <div className="bg-white shadow-sm">
              <img src="./sp4.png" alt="" className="object-cover w-full h-[230px]" />
              <div className="px-7 pt-2.5 pb-5">
                <span className="font-din mb-2 block text-xl">Photo based Printing</span>
                <a href="https://pinholepress.com" target="_target" className="group flex items-center space-x-3 uppercase">
                  <span className="transition group-hover:opacity-80 font-d-din text-[#4D4D4D]">
                    Learn More
                  </span>
                  <MoveRight className="text-[#4D4D4D] transition group-hover:translate-x-3" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-16 lg:pr-28" id="direct_mail">
        <div className="container flex flex-wrap gap-x-24 gap-y-10">
          <div className="max-w-[750px] w-full">
            <img src="./fulfillment.png" alt="" className="w-full object-cover" />
          </div>
          <div className="max-w-sm">
            <p className="mb-3 font-din text-[40px]">Direct Mail and Fulfillment</p>
            <p className="font-ibm">
            With digital print solutions like web-to-print 
            storefronts, cross-media, and digital asset 
            management, we make it easy for you to connect 
            with your customers and prospects. From simple 
            mail merges to highly sophisticated one-to-one 
            marketing campaigns, we can help personalize 
            your message with variable text, images, and 
            graphics based on logic. 
            <br />
            <br />
            We specialize in Direct Mail and Political Printing 
            with our Union label. As the campaign season 
            ramps up, so do we. Our fast turnarounds, 
            reliable service, and dedicated political team, 
            we are the most chosen political printers in Florida.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
