import React from "react";

export default function ThankYou() {
  return (
      <div className="bg-white">
          <div className="container py-24 flex flex-wrap gap-10">
              <div className="max-w-sm flex flex-col justify-center">
                  <h2 className="font-bold font-din text-[40px]">Thank You</h2>
                  <p className="font-ibm">
                      Thank you for contacting Lawton
                      Connect about your project. We
                      appreciate the opportunity to work
                      with you, and a member of our team
                      will connect with you soon.
                      <br/><br/>
                      In the meantime, please explore our
                      website to learn more about how we
                      can help you communicate, captivate,
                      and connect.
                  </p>
              </div>
              <div className="flex-1 flex">
              <div className="grid grid-cols-3 gap-4 justify-around">
                  <img src="./Rectangle 34.png" alt="Image 1" className="w-[215px] h-[168px]"/>
                  <img src="./rec.jpg" alt="Image 2" className="w-[330px] h-[193px]"/>
                  <img src="./Rectangle 72.png" alt="Image 3" className="w-[200px] h-[193px]"/>

                  <img src="./Rectangle 69.png" alt="Image 4" className="max-w-lg h-[142px]"/>
                  <img src="./Rectangle 71.png" alt="Image 5" className="w-full"/>
              </div>
              </div>
          </div>
      </div>
  );
}
