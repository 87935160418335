import { MoveRight } from "lucide-react";
import {useEffect} from "react";

export default function SisterCompanies() {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);
  return (
    <div className="bg-white py-12" id="sister-companies">
      <div className="container">
        <h4 className="font-bold text-[32px] leading-none font-din">Our Subsidiaries</h4>
        <hr className="bg-[#5A5A5A] my-8" />
        <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-16">
          <div className="flex flex-col">
            <img src="./resized-MSO.png" alt="" className="h-[220px] w-full object-cover mb-3" />
            <div className="flex flex-col pl-4 flex-1">
              <p className="font-bold font-din mb-2">Mr. Scratch Off</p>
              <p className="text-sm mb-6 leading-tight font-ibm pr-3">
                The anticipation that comes with a scratch off product 
                doesn&apos;t just make the experience memorable, it has 
                them coming back for more. Create custom scratch 
                offs for fundraisers, marketing events, employee 
                incentives and more.
              </p>
              <a href="https://mrscratchoff.com" target="_blank" rel="noreferrer" className="group font-bold flex items-center space-x-3 uppercase mt-auto">
                <span className="transition group-hover:opacity-80 font-d-din-normal font-bold text-[#4D4D4D]">
                  Learn More
                </span>
                <MoveRight className="text-[#4D4D4D] transition group-hover:translate-x-3" />
              </a>
            </div>
          </div>
          <div className="flex flex-col">
            <img src="./rec5.png" alt="" className="h-[220px] w-full border-2 object-cover mb-3" />
            <div className="flex flex-col pl-4 flex-1">
              <p className="font-bold font-din mb-2">Mr. Playing Card</p>
              <p className="text-sm mb-6 leading-tight font-ibm pr-3">
                Whether jokers are wild or you have an ace up your 
                sleeve, you control the game. Create new ways to play 
                and new possibilities designing your custom playing cards.
              </p>
              <a href="https://mrplayingcard.com" target="_blank" rel="noreferrer" className="group font-bold flex items-center space-x-3 uppercase mt-auto">
                <span className="font-d-din font-bold text-[#4D4D4D]">Learn More</span>
                <MoveRight className="text-[#4D4D4D] transition group-hover:translate-x-3" />
              </a>
            </div>
          </div>
          <div className="flex flex-col">
            <img src="./rec6.png" alt="" className="h-[220px] w-full object-cover mb-3" />
            <div className="flex flex-col pl-4 flex-1">
              <p className="font-bold font-din mb-2">Pinhole Press</p>
              <p className="text-sm mb-6 leading-tight font-ibm pr-3">
                If you're shopping for the perfect gift, heartwarming holiday cards or a photo album
                to preserve your favorite memories, Pinhole Press is here to help you preserve
                life's special moments.
              </p>
              <a href="http://pinholepress.com" target="_blank" rel="noreferrer" className="group font-bold flex items-center space-x-3 uppercase mt-auto">
                <span className="font-d-din font-bold text-[#4D4D4D]">Learn More</span>
                <MoveRight className="text-[#4D4D4D] transition group-hover:translate-x-3" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
