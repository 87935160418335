import React, {useState} from "react";
import { useNavigate } from 'react-router-dom';
import {FaSpinner} from "react-icons/fa";

export default function ContactUs() {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [phone, setPhone] = useState('');
  const [preferred_response, setContactPreference] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // Example server endpoint
    const serverEndpoint = `${process.env.REACT_APP_BASE_API_URL}/contacts`;

    const source  = "contact_us"

    // Form data to be submitted
    const formData = {name, email, phone, company, preferred_response, message, source};

    try {
      const response = await fetch(serverEndpoint, {
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          'Site': `${process.env.REACT_APP_SITE_ID}`
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setIsLoading(false);
        // Form submitted successfully, redirect to another page
        navigate('/thank-you'); // Redirect to a Thank You page, for example
      } else {
        // Handle server errors or invalid responses
        setIsLoading(false);
        setErrorText(`An error occurred while submitting the form. Please try again later: ${response}`);
        console.error('Server error:', response);
      }
    } catch (error) {
      // Handle network errors
      setIsLoading(false);
      setErrorText(`A network error occurred while submitting the form. Please check your internet connection and try again: ${error}.`);
      console.error('Network error:', error);
    }
  };

const handleInputChange = (event) => {
  const { name, value } = event.target;
  if (name === 'name') setName(value);
  if (name === 'email') setEmail(value);
  if (name === 'phone') setPhone(value);
  if (name === 'company') setCompany(value);
  if (name === 'contact_preference') setContactPreference(value);
  if (name === 'message') setMessage(value);
};

  return (
    <div className="py-12 bg-white">
      <div className="flex container justify-between flex-wrap gap-x-28 gap-y-12">
        <div className="flex-1 flex flex-col gap-10">
          <div className="max-w-md">
            <h2 className="font-din font-bold text-[40px]">Contact Us</h2>
            <p className="font-ibm text-lg font-light">
              Thank you for visiting Lawton Connect.
              Do you have a question about our
              services or solutions? Do you have a
              success story or feedback you&apos;d like to
              share? We want to hear from you!
              Please submit your questions or
              feedback here.
            </p>
          </div>
          <div className="font-ibm text-sm">
            {errorText && <p className="text-red-500 text-center">{errorText}</p>}
            <p className="mb-5">Fields marked with an asterisk (*) are required.</p>
            <form onSubmit={handleSubmit} className="flex flex-col space-y-1.5">
              <div className="flex items-center justify-between flex-wrap gap-2">
                <label className="w-[150px]" htmlFor="name">
                  Name *
                </label>
                <input
                    onChange={handleInputChange}
                  type="text"
                  name="name"
                  id="name"
                  className="rounded w-[250px] flex-1 bg-transparent sm:max-w-lg px-3 py-1.5 min-w-[350px] outline-none border-[0.5px] border-black"
                />
              </div>
              <div className="flex items-center justify-between flex-wrap gap-2">
                <label className="w-[150px]" htmlFor="company">
                  Company *
                </label>
                <input
                    onChange={handleInputChange}
                  type="text"
                  name="company"
                  id="company"
                  className="rounded w-[250px] bg-transparent flex-1 sm:max-w-lg px-3 py-1.5 min-w-[350px] outline-none border-[0.5px] border-black"
                />
              </div>
              <div className="flex items-center justify-between flex-wrap gap-2">
                <label className="w-[150px]" htmlFor="phone">
                  Phone *
                </label>
                <input
                    onChange={handleInputChange}
                  type="text"
                  name="phone"
                  id="phone"
                  className="rounded w-[250px] bg-transparent flex-1 sm:max-w-lg px-3 py-1.5 min-w-[350px] outline-none border-[0.5px] border-black"
                />
              </div>
              <div className="flex items-center justify-between flex-wrap gap-2">
                <label className="w-[150px]" htmlFor="email">
                  Email *
                </label>
                <input
                    onChange={handleInputChange}
                  type="text"
                  name="email"
                  id="email"
                  className="rounded w-[250px] bg-transparent flex-1 sm:max-w-lg px-3 py-1.5 min-w-[350px] outline-none border-[0.5px] border-black"
                />
              </div>
              <div className="flex items-center justify-between flex-wrap gap-2">
                <label className="w-[150px]" htmlFor="project-name">
                  How do you prefer to be contacted?
                </label>
                <input
                    onChange={handleInputChange}
                  type="text"
                  name="contact_preference"
                  id="contact-preference"
                  className="rounded bg-transparent flex-1 sm:max-w-lg px-3 py-1.5 min-w-[350px] outline-none border-[0.5px] border-black"
                />
              </div>
              <div className="flex items-center justify-between flex-wrap gap-2">
                <label className="w-[150px]" htmlFor="add-details">
                  Questions / Comments?
                </label>
                <textarea
                    onChange={handleInputChange}
                  type="text"
                  name="message"
                  rows={3}
                  id="add-details"
                  className="rounded bg-transparent flex-1 sm:max-w-lg px-3 py-1.5 min-w-[350px] outline-none border-[0.5px] border-black"
                ></textarea>
              </div>
              <div className="flex !mt-8">
                <div className="w-[150px]"></div>
                <button className="rounded-full font-d-din-normal bg-black text-white py-3 px-12" disabled={isLoading}>
                  {isLoading && <FaSpinner className="animate-spin"/>}
                  {isLoading ? 'LOADING...' : 'SUBMIT'}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="flex-1">
          <div className="mb-3 flex justify-between font-ibm font-light text-lg">
            <div className="flex flex-col">
              <span>Lawton Connect</span>
              <span>649 Triumph Court</span>
              <span>Orlando, FL 32805</span>
            </div>
            <div className="flex flex-col">
              <span>Phone: 407-260-0400</span>
              <span>Toll-Free: 877-330-1900</span>
            </div>
          </div>
          <div className="">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.698887440358!2d-81.4141565878347!3d28.548769087776265!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e771fb7f805b03%3A0xf3eea27f40fc51de!2sLawton%20Connect!5e0!3m2!1sen!2sus!4v1709216098196!5m2!1sen!2sus"
              height="450"
              className="border-0 w-full"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}
