import React from "react";
import { Facebook, Instagram, Linkedin } from "lucide-react";

export default function Footer() {
  return (
    <div className="bg-[#090909]">
      <div className="container text-white flex flex-col">
        <div className="flex justify-between py-16 font-ibm flex-wrap gap-12">
          <div className="flex flex-col text-center">
            <a href="/" className="" aria-label="home">
              <img src="./Frame.svg" alt="logo" className="w-[160px]" />
            </a>
            <div className="mb-4 mt-6 font-light">
              <p className="text-center leading-snug">649 Triumph Court</p>
              <p className="text-center leading-snug">Orlando, FL 32805</p>
            </div>
          </div>
          <div className="flex gap-16 flex-wrap">
            <div className="flex flex-col space-y-2.5">
              <span className="tracking-widest font-din font-thin text-gray-400 text-sm mb-2">
                WHO WE ARE
              </span>
              <a href="/who-we-are" className="font-light transition hover:opacity-80">
                Our Story
              </a>
              <a href="/who-we-are#our-commitment" className="font-light transition hover:opacity-80">
                Our Commitment
              </a>
              <a href="/who-we-are#core-values" className="font-light transition hover:opacity-80">
                Core Values
              </a>
              <a href="/who-we-are#sister-companies" className="font-light transition hover:opacity-80">
                Our Subsidiaries
              </a>
            </div>
            <div className="flex flex-col space-y-2.5">
              <span className="tracking-widest font-din text-gray-400 text-sm font-bold mb-2">
                SOLUTIONS
              </span>
              <a href="/solutions#printing" className="font-light transition hover:opacity-80">
                Sheetfed Printing
              </a>
              <a href="/solutions#wide_format" className="font-light transition hover:opacity-80">
                Wide Format
              </a>
              <a href="/solutions#menu_printing" className="font-light transition hover:opacity-80">
                Menu Printing
              </a>
              <a href="/solutions#speciality" className="font-light transition hover:opacity-80">
                Specialty
              </a>
              <a href="/solutions#direct_mail" className="font-light transition hover:opacity-80">
                Direct Mail and Fulfillment
              </a>
            </div>
            <div className="flex flex-col space-y-2.5">
              <span className="tracking-widest font-din text-gray-400 text-sm font-bold mb-2">
                PORTFOLIO
              </span>
              <a href="/portfolio#gallery" className="font-light transition hover:opacity-80">
                Gallery
              </a>
              <a href="/portfolio#testimonials" className="font-light transition hover:opacity-80">
                Testimonials
              </a>
            </div>
            <div className="flex flex-col space-y-2.5">
              <span className="tracking-widest font-din text-gray-400 text-sm font-bold mb-2">
                CONNECT
              </span>
              <a href="/request-an-estimate" className="font-light transition hover:opacity-80">
                Get a Quote
              </a>
              <a href="/send-a-file" className="font-light transition hover:opacity-80">
                Upload Files
              </a>
              <a href="/contact-us" className="font-light transition hover:opacity-80">
                Contact Us
              </a>
              <a href="/faq" className="font-light transition hover:opacity-80">
                FAQ
              </a>
              <a href="/privacy-policy" className="font-light transition hover:opacity-80">
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
        <hr className="border-white"/>
        <div className="flex items-center justify-between py-4 gap-5 flex-wrap">
          <div className="font-light">© 2024 Lawton Connect. All Rights Reserved.</div>
          <div className="flex space-x-5">
            <a aria-label="linkedin link" target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/lawton-printers/mycompany/verification/" className="transition hover:opacity-80">
              <Linkedin />
            </a>
            <a aria-label="instagram link" target="_blank" rel="noreferrer" href="https://www.instagram.com/lawtonconnect/" className="transition hover:opacity-80">
              <Instagram />
            </a>
            <a aria-label="facebook link" target="_blank" rel="noreferrer" href="https://www.facebook.com/lawtonconnect" className="transition hover:opacity-80">
              <Facebook />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
